import useGatekeeper from "@/hooks/useGatekeeper";
import Dropdown, { Option } from "@/ui-lib/components/Dropdown";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import IconAzure from "@/ui-lib/icons/IconAzure";
import IconExclamationDiamond from "@/ui-lib/icons/IconExclamationDiamond";
import { useTheme } from "@emotion/react";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import {
  CloudCapability,
  DataSource,
  DataStatusType,
} from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import React from "react";
import copyText from "../copyText";
import DataStatusTooltip from "./DataStatusTooltip";

type Cloud = {
  id: string;
  config: {
    appID: string | null;
    billingExports:
      | {
          dateColumn: string | null;
          endDate: string | null;
          serviceURL: string;
          startDate: string | null;
          storageContainer: string;
          storagePrefix: string | null;
          strictStoragePrefix: boolean;
        }[]
      | null;
    directoryID: string | null;
    type: string | null;
  };
  dataStatus: {
    [DataSource.BILLING]: {
      latestRefresh: string | null;
      latestUpstreamCheck: string | null;
      latestUpstreamTimestamp: string | null;
      performanceMode: DataStatusType;
    };
  };
  name: string;
  validations: { error: string; name: string; success: boolean }[];
};

interface Props {
  integration: Cloud;
  isProcessing: boolean;
  onInteraction: (interaction: AzureCloudCard.Interaction) => void;
}

export function AzureCloudCard(props: Props): JSX.Element {
  const theme = useTheme();
  const gatekeeper = useGatekeeper();
  const validation = props.integration.validations.find(
    (validation) => validation.name === CloudCapability.BILL_DATA_AZURE
  );
  const error = validation?.error ? validation?.error : null;

  const options: Option[] = [];

  if (gatekeeper.canUpdateDataIntegration) {
    options.push(
      {
        label: copyText.actionMenuItemEditCloud,
        onClick: () =>
          props.onInteraction({
            type: AzureCloudCard.INTERACTION_EDIT_BUTTON_CLICKED,
            integrationID: props.integration.id,
          }),
      },
      {
        label: props.isProcessing ? (
          <Flex alignItems="center" justifyContent="space-between">
            <Text>{copyText.actionMenuItemValidateCloud}</Text>
            <LoadingSpinner />
          </Flex>
        ) : (
          copyText.actionMenuItemValidateCloud
        ),
        locked: props.isProcessing,
        onClick: () =>
          props.onInteraction({
            type: AzureCloudCard.INTERACTION_REFRESH_BUTTON_CLICKED,
            integrationID: props.integration.id,
          }),
      }
    );
  }

  if (gatekeeper.canDeleteDataIntegration) {
    options.push({
      label: copyText.actionMenuItemDeleteCloud,
      onClick: () =>
        props.onInteraction({
          type: AzureCloudCard.INTERACTION_DELETE_BUTTON_CLICKED,
          integrationID: props.integration.id,
        }),
    });
  }

  const typeOptions = [
    {
      label: copyText.azureCloudFormTypeAUTOLabel,
      value: copyText.azureCloudFormTypeAUTO,
    },
    {
      label: copyText.azureCloudFormTypeEALabel,
      value: copyText.azureCloudFormTypeEA,
    },
    {
      label: copyText.azureCloudFormTypePAYGLabel,
      value: copyText.azureCloudFormTypePAYG,
    },
    {
      label: copyText.azureCloudFormTypeMCALabel,
      value: copyText.azureCloudFormTypeMCA,
    },
    {
      label: copyText.azureCloudFormTypeAPNALabel,
      value: copyText.azureCloudFormTypeAPNA,
    },
    {
      label: copyText.azureCloudFormTypeAPNALabel,
      value: copyText.azureCloudFormTypeAPNA,
    },
    {
      label: copyText.azureCloudFormTypeMPAv2Label,
      value: copyText.azureCloudFormTypeMPAv2,
    },
  ];

  const selectedType = typeOptions.find(
    (option) => option.value === props.integration.config.type
  );

  return (
    <Flex
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_2}
      direction="column"
      justifyContent="space-between"
      marginBottom={theme.space_lg}
      marginRight={theme.space_lg}
      padding={theme.space_md}
      width={360}
      height={360}
      scrollable
    >
      <Box>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          marginBottom={theme.space_sm}
        >
          <Flex alignItems="center">
            <Flex
              alignItems="center"
              backgroundColor="white"
              border={`1px solid ${theme.border_color}`}
              borderRadius="50%"
              height={48}
              justifyContent="center"
              marginRight={theme.space_sm}
              minWidth={48}
            >
              <IconAzure size={36} />
            </Flex>
            <Text fontSize={theme.h4_fontSize} marginRight={theme.space_xxs}>
              {props.integration.name}
            </Text>
            <DataStatusTooltip
              latestRefresh={props.integration.dataStatus.BILLING.latestRefresh}
              latestUpstream={
                props.integration.dataStatus.BILLING.latestUpstreamTimestamp
              }
            />
            {error && (
              <Tooltip
                content={
                  <Flex maxWidth={500} minWidth={325}>
                    {error}
                  </Flex>
                }
              >
                <IconExclamationDiamond size="13px" />
              </Tooltip>
            )}
          </Flex>
          <Dropdown options={options} placement="bottom-end">
            <Button iconEnd={<Icon icon={faEllipsisV} />} />
          </Dropdown>
        </Flex>
        <Box>
          <Box marginBottom={theme.space_md}>
            <Text bold>{copyText.appIDLabel}</Text>
            <Text>{props.integration.config.appID}</Text>
          </Box>
          <Box marginBottom={theme.space_md}>
            <Text bold>{copyText.cloudAzureAttributeDirectory}</Text>
            <Text>{props.integration.config.directoryID}</Text>
          </Box>
          <Box marginBottom={theme.space_md}>
            <Text bold>{copyText.cloudAzureAttributeAzureType}</Text>
            <Text>{selectedType?.label}</Text>
          </Box>
          <Box marginRight={theme.space_md}>
            <Text bold>{copyText.azureBillingExportsLabel}</Text>
            {props.integration.config.billingExports
              ? props.integration.config.billingExports.map((cur, index) => {
                  return (
                    <Box key={index}>
                      <Box>
                        <Text bold marginTop={theme.space_sm}>
                          {copyText.azureCloudCardServiceUrl}
                        </Text>
                        <Text overflowWrap="break-word">{`${cur.serviceURL}/${cur.storageContainer}/${cur.storagePrefix}`}</Text>
                      </Box>
                      <Text bold marginTop={theme.space_sm}>
                        {copyText.azureCloudCardDateRange}
                      </Text>
                      <Flex justifyContent="space-between">
                        <Text>
                          {`${copyText.azureCloudCardDateRangeStartLabel} ${
                            cur.startDate
                              ? formatDate(
                                  new Date(cur.startDate),
                                  "MM-dd-yyyy"
                                )
                              : ""
                          }`}
                        </Text>
                        <Text>
                          {`${copyText.azureCloudCardDateRangeEndLabel} ${
                            cur.endDate
                              ? formatDate(new Date(cur.endDate), "MM-dd-yyyy")
                              : ""
                          }`}
                        </Text>
                      </Flex>
                    </Box>
                  );
                })
              : copyText.noCURsMessage}
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}

AzureCloudCard.INTERACTION_EDIT_BUTTON_CLICKED =
  `AzureCloudCard.INTERACTION_EDIT_BUTTON_CLICKED` as const;

AzureCloudCard.INTERACTION_REFRESH_BUTTON_CLICKED =
  `AzureCloudCard.INTERACTION_REFRESH_BUTTON_CLICKED` as const;

AzureCloudCard.INTERACTION_DELETE_BUTTON_CLICKED =
  `AzureCloudCard.INTERACTION_DELETE_BUTTON_CLICKED` as const;

interface InteractionEditButtonClicked {
  type: typeof AzureCloudCard.INTERACTION_EDIT_BUTTON_CLICKED;
  integrationID: string;
}
interface InteractionRefreshButtonClicked {
  type: typeof AzureCloudCard.INTERACTION_REFRESH_BUTTON_CLICKED;
  integrationID: string;
}
interface InteractionDeleteButtonClicked {
  type: typeof AzureCloudCard.INTERACTION_DELETE_BUTTON_CLICKED;
  integrationID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AzureCloudCard {
  export type Interaction =
    | InteractionEditButtonClicked
    | InteractionRefreshButtonClicked
    | InteractionDeleteButtonClicked;
}

export default AzureCloudCard;
