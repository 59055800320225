import { useTheme } from "@emotion/react";
import { useQueryClient } from "@tanstack/react-query";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import {
  CloudProviderType,
  RateRecommendationType,
  ServiceType,
} from "@ternary/api-lib/constants/enums";
import { Role } from "@ternary/api-lib/constants/roles";
import PieChart from "@ternary/api-lib/ui-lib/charts/PieChart";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { format } from "date-fns";
import { keyBy, sortBy } from "lodash";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { ArrayParam, useQueryParams, withDefault } from "use-query-params";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../hooks/useGatekeeper";
import ConfirmationModal from "../../../ui-lib/components/ConfirmationModal";
import SelectCheckbox, {
  Option,
} from "../../../ui-lib/components/SelectCheckbox";
import IconExclamationDiamond from "../../../ui-lib/icons/IconExclamationDiamond";
import IconExport from "../../../ui-lib/icons/IconExport";
import { updateAuthenticatedUserGrants } from "../../../utils/QueryClientUtils";
import { AlertType, postAlert } from "../../../utils/alerts";
import getMergeState from "../../../utils/getMergeState";
import useGrantUsersTenantAccess from "../../admin/hooks/useGrantUsersTenantAccess";
import useGetTenantsByParentTenantID from "../../global-admin/hooks/useGetTenantsByParentTenantID";
import copyText from "../copyText";
import useGetMspChildResources from "../hooks/useGetMspChildResources";
import { parseArrayParam } from "./MspDashboardPage";
import MspRateRecommendationTable from "./MspRateRecommendationTable";
import MspUsageRecommendationTable from "./MspUsageRecommendationTable";

const rateCsvHeaders = [
  { key: "tenantName", label: copyText.tableHeaderTenantName },
  { key: "term", label: copyText.tableHeaderTerm },
  { key: "type", label: copyText.tableHeaderType },
  {
    key: "estimatedMonthlySavings",
    label: copyText.tableHeaderPotentialSavingsMonthly,
  },
];

const usageCsvHeaders = [
  { key: "tenantName", label: copyText.tableHeaderTenantName },
  { key: "serviceType", label: copyText.tableHeaderServiceType },
  { key: "estimateValue", label: copyText.tableHeaderPotentialSavings },
];

type Interaction =
  | MspRateRecommendationTable.Interaction
  | MspUsageRecommendationTable.Interaction;

const defaultMspResources = {
  alerts: [],
  alertSummaries: [],
  rateRecommendations: [],
  rateRecommendationSummaries: [],
  usageRecommendations: [],
  usageRecommendationSummaries: [],
};

type State = {
  cloudProviderTypes: string[];
  rateRecTypes: string[];
  selectedTenantID: string | null;
  serviceTypes: string[];
  showModal: boolean;
  terms: string[];
};

const initialState: State = {
  cloudProviderTypes: [],
  rateRecTypes: [],
  selectedTenantID: null,
  serviceTypes: [],
  showModal: false,
  terms: [],
};

interface Props {
  parentTenantID: string;
}

export default function MspRecommendationViewContainer(props: Props) {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const queryClient = useQueryClient();
  const theme = useTheme();

  //
  // State
  //

  const [searchParamState] = useQueryParams({
    t_ids: withDefault(ArrayParam, []),
  });

  const tenantDocIDs = parseArrayParam(searchParamState.t_ids);

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data: _mspTenants = [], isLoading: isLoadingMspTenants } =
    useGetTenantsByParentTenantID(props.parentTenantID, {
      enabled: gatekeeper.canReadTenantsPartner,
    });

  const tenantsKeyedByDocID = keyBy(_mspTenants, "fsDocID");

  const {
    data: mspResources = defaultMspResources,
    isLoading: isLoadingMspResources,
  } = useGetMspChildResources(props.parentTenantID);

  //
  // Mutations
  //

  const {
    isPending: isGrantingUserTenantAccess,
    mutate: grantUsersTenantAccess,
  } = useGrantUsersTenantAccess({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorGrantingUserTenantAccessMessage,
      });
    },
    onSuccess: (_, params) => {
      updateAuthenticatedUserGrants(queryClient, params);

      window.open(
        `${window.location.origin}/?tenant_id=${params.tenantID}`,
        "_blank"
      );

      mergeState({ selectedTenantID: null, showModal: false });
    },
  });

  //
  // InteractionHandlers
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case MspRateRecommendationTable.INTERACTION_LINK_CLICKED:
      case MspUsageRecommendationTable.INTERACTION_LINK_CLICKED: {
        const tenant = tenantsKeyedByDocID[interaction.tenantDocID];

        mergeState({
          selectedTenantID: tenant.id,
          showModal: true,
        });
        return;
      }
    }
  }

  function handleSubmitTenantAccessModal() {
    if (!state.selectedTenantID) return;

    grantUsersTenantAccess({
      tenantID: state.selectedTenantID,
      grants: [
        {
          userID: authenticatedUser.id,
          email: authenticatedUser.email,
          roles: [Role.TENANT_ADMIN],
        },
      ],
    });
  }

  //
  // Render
  //

  const _rateRecs = mspResources.rateRecommendations.map((rec) => ({
    ...rec,
    tenantName: tenantsKeyedByDocID[rec.tenantDocID]?.name ?? "null",
  }));

  const _usageRecs = mspResources.usageRecommendations.map((rec) => ({
    ...rec,
    tenantName: tenantsKeyedByDocID[rec.tenantDocID]?.name ?? "null",
  }));

  const rateRecSummaries = mspResources.rateRecommendationSummaries.map(
    (summary) => ({
      ...summary,
      tenantName: tenantsKeyedByDocID[summary.tenantDocID]?.name ?? "null",
    })
  );

  const usageRecSummaries = mspResources.usageRecommendationSummaries.map(
    (summary) => ({
      ...summary,
      tenantName: tenantsKeyedByDocID[summary.tenantDocID]?.name ?? "null",
    })
  );

  let rateRecs = _rateRecs;

  if (
    tenantDocIDs.length > 0 ||
    state.rateRecTypes.length > 0 ||
    state.terms.length > 0
  ) {
    rateRecs = _rateRecs.filter((rec) => {
      const tenantMatch =
        tenantDocIDs.length > 0 ? tenantDocIDs.includes(rec.tenantDocID) : true;

      const rateRecTypesMatch =
        state.rateRecTypes.length > 0
          ? state.rateRecTypes.includes(rec.type)
          : true;

      const termsMatch =
        state.terms.length > 0 ? state.terms.includes(rec.term) : true;

      return tenantMatch && rateRecTypesMatch && termsMatch;
    });
  }

  let usageRecs = _usageRecs;

  if (
    tenantDocIDs.length > 0 ||
    state.cloudProviderTypes.length > 0 ||
    state.serviceTypes.length > 0
  ) {
    usageRecs = _usageRecs.filter((rec) => {
      const tenantMatch =
        tenantDocIDs.length > 0 ? tenantDocIDs.includes(rec.tenantDocID) : true;

      const cloudProviderMatch =
        state.cloudProviderTypes.length > 0
          ? state.cloudProviderTypes.includes(rec.cloudProviderType)
          : true;

      const serviceTypeMatch =
        state.serviceTypes.length > 0
          ? state.serviceTypes.includes(rec.serviceType)
          : true;

      return tenantMatch && cloudProviderMatch && serviceTypeMatch;
    });
  }

  let filteredRateRecSummaries =
    tenantDocIDs.length > 0
      ? rateRecSummaries?.filter((summary) =>
          tenantDocIDs.includes(summary.tenantDocID)
        )
      : rateRecSummaries;

  filteredRateRecSummaries =
    rateRecs?.length === 0 ? [] : filteredRateRecSummaries;

  let filteredUsageRecSummaries =
    tenantDocIDs.length > 0
      ? usageRecSummaries?.filter((summary) =>
          tenantDocIDs.includes(summary.tenantDocID)
        )
      : usageRecSummaries;

  filteredUsageRecSummaries =
    usageRecs.length === 0 ? [] : filteredUsageRecSummaries;

  const cloudProviderTypeOptions = [
    {
      label: copyText.providerTypeLabel_AWS,
      value: CloudProviderType.AWS,
    },
    {
      label: copyText.providerTypeLabel_AZURE,
      value: CloudProviderType.AZURE,
    },
    {
      label: copyText.providerTypeLabel_GCP,
      value: CloudProviderType.GCP,
    },
  ];

  const serviceTypeOptions = sortBy(Object.values(ServiceType), (value) =>
    value.toLowerCase()
  ).reduce(
    (accum: Option[], value) =>
      value === ServiceType.ANMLY
        ? accum
        : [
            ...accum,
            {
              label: copyText[`serviceTypeLabel_${value}`],
              value,
            },
          ],
    []
  );

  const termOptions = [
    { label: copyText.rateRecTermLabel_ONE_YEAR, value: "ONE_YEAR" },
    { label: copyText.rateRecTermLabel_THREE_YEARS, value: "THREE_YEARS" },
  ];

  const rateRecTypeOptions = [
    {
      label: copyText.rateRecTypeLabel_COMMITTED_USE_DISCOUNT,
      value: RateRecommendationType.COMMITTED_USE_DISCOUNT,
    },
    {
      label: copyText.rateRecTypeLabel_RESERVED_INSTANCE_AWS,
      value: RateRecommendationType.RESERVED_INSTANCE_AWS,
    },
    {
      label: copyText.rateRecTypeLabel_RESERVED_INSTANCE_AZURE,
      value: RateRecommendationType.RESERVED_INSTANCE_AZURE,
    },
    {
      label: copyText.rateRecTypeLabel_SAVINGS_PLAN_AWS,
      value: RateRecommendationType.SAVINGS_PLAN_AWS,
    },
    {
      label: copyText.rateRecTypeLabel_SAVINGS_PLAN_AZURE,
      value: RateRecommendationType.SAVINGS_PLAN_AZURE,
    },
  ];

  const isLoading = isLoadingMspResources || isLoadingMspTenants;

  return (
    <Box>
      {state.showModal && (
        <ConfirmationModal
          isLoading={isGrantingUserTenantAccess}
          message={copyText.tenantAccessModalMessage}
          title={copyText.tenantAccessModalTitle}
          onCancel={() => mergeState({ showModal: false })}
          onConfirm={handleSubmitTenantAccessModal}
        />
      )}
      <Flex justifyContent="space-between">
        <Box width="52%">
          <Flex
            alignItems="center"
            justifyContent="space-between"
            marginBottom={theme.space_xs}
          >
            <Flex alignItems="center">
              <Text appearance="h3" marginRight={theme.space_sm}>
                {copyText.sectionHeaderUsageOptimizations}
              </Text>
              {usageRecs.length === 0 && tenantDocIDs.length > 0 && (
                <Tooltip
                  content={copyText.filterTenantsNoDataMessage.replace(
                    "%RESOURCE%",
                    copyText.filterTenantsUsageOptimizationText
                  )}
                  placement="right"
                  width={"15rem"}
                >
                  <IconExclamationDiamond size={"20px"} />
                </Tooltip>
              )}
            </Flex>
            <Flex alignItems="center">
              <Box marginRight={theme.space_sm}>
                <SelectCheckbox
                  compact
                  hideSearch
                  options={cloudProviderTypeOptions}
                  placeholder="Providers"
                  selectedValues={state.cloudProviderTypes}
                  width={150}
                  onChange={(values) =>
                    mergeState({ cloudProviderTypes: values })
                  }
                />
              </Box>
              <Box marginRight={theme.space_sm}>
                <SelectCheckbox
                  compact
                  hideSearch
                  options={serviceTypeOptions}
                  placeholder="Services"
                  selectedValues={state.serviceTypes}
                  width={150}
                  onChange={(values) => mergeState({ serviceTypes: values })}
                />
              </Box>
              <CSVLink
                data={usageRecs}
                headers={usageCsvHeaders}
                filename={`msp-usage-recs-${format(new Date(), "MM-dd-yyyy")}`}
              >
                <Button iconStart={<IconExport />} secondary size="small">
                  {copyText.exportButtonLabel}
                </Button>
              </CSVLink>
            </Flex>
          </Flex>
          <MspUsageRecommendationTable
            isLoading={isLoading}
            recommendations={usageRecs}
            onInteraction={handleInteraction}
          />
        </Box>
        <Box height={400} width="45%">
          <Text appearance="h3">
            {copyText.sectionHeaderSavingsOpportunities}
          </Text>
          <PieChart
            data={filteredUsageRecSummaries ?? []}
            dimensions={[{ name: "tenantName", isDate: false }]}
            isLoading={isLoading}
            measures={[{ name: "savingsEstimate", unit: UnitType.CURRENCY }]}
            readableKeys={{ tenantName: copyText.tableHeaderTenantName }}
            showFullLabel
            showTooltip
          />
        </Box>
      </Flex>
      <Flex justifyContent="space-between">
        <Box width="52%">
          <Flex
            alignItems="center"
            justifyContent="space-between"
            marginBottom={theme.space_xs}
          >
            <Flex alignItems="center">
              <Text appearance="h3" marginRight={theme.space_sm}>
                {copyText.sectionHeaderRateOptimizations}
              </Text>
              {rateRecs?.length === 0 && searchParamState.t_ids.length > 0 && (
                <Tooltip
                  content={copyText.filterTenantsNoDataMessage.replace(
                    "%RESOURCE%",
                    copyText.filterTenantsRateOptimizationText
                  )}
                  placement="right"
                  width={"15rem"}
                >
                  <IconExclamationDiamond size={"20px"} />
                </Tooltip>
              )}
            </Flex>
            <Flex alignItems="center">
              <Box marginRight={theme.space_sm}>
                <SelectCheckbox
                  compact
                  hideSearch
                  options={termOptions}
                  placeholder="Term"
                  selectedValues={state.terms}
                  width={150}
                  onChange={(values) => mergeState({ terms: values })}
                />
              </Box>
              <Box marginRight={theme.space_sm}>
                <SelectCheckbox
                  compact
                  hideSearch
                  options={rateRecTypeOptions}
                  placeholder="Type"
                  selectedValues={state.rateRecTypes}
                  width={200}
                  onChange={(values) => mergeState({ rateRecTypes: values })}
                />
              </Box>
              <CSVLink
                data={rateRecs ?? []}
                headers={rateCsvHeaders}
                filename={`msp-rate-recs-${format(new Date(), "MM-dd-yyyy")}`}
              >
                <Button iconStart={<IconExport />} secondary size="small">
                  {copyText.exportButtonLabel}
                </Button>
              </CSVLink>
            </Flex>
          </Flex>
          <MspRateRecommendationTable
            isLoading={isLoading}
            recommendations={rateRecs ?? []}
            onInteraction={handleInteraction}
          />
        </Box>
        <Box height={400} width="45%">
          <Text appearance="h3">
            {copyText.sectionHeaderSavingsOpportunities}
          </Text>
          <PieChart
            data={filteredRateRecSummaries ?? []}
            dimensions={[{ name: "tenantName", isDate: false }]}
            isLoading={isLoading}
            measures={[
              { name: "monthlySavingsEstimate", unit: UnitType.CURRENCY },
            ]}
            readableKeys={{ tenantName: copyText.tableHeaderTenantName }}
            showFullLabel
            showTooltip
          />
        </Box>
      </Flex>
    </Box>
  );
}
