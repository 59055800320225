import common from "../../common.copyText";

/* prettier-ignore */
export default {
  ...common,
  allTenantsLabel: "All",
  cloudsTooltipContent: "Disabling this option will disable the clouds tab for your non MSP users in all child tenants.",
  createRuleSetButtonLabel: "Create Rule Set",
  createRuleSetTooltipContent: "Create a set of rules that will be applied each time your billing data is updated in Ternary.",
  deleteRuleSetModalMessage: "This will permenantly delete the ruleset and all its rules. Are you sure you want to do this?",
  deleteRuleSetModalTitle: "Delete Rule Set",
  dimensionLabel_billingAccountId: "Billing Account ID",
  dimensionLabel_provider: "Provider",
  dimensionLabel_region: "Region",
  dimensionLabel_serviceName: "Service Name",
  errorCreatingRuleSetMessage: "An error occured while attempting to create the rule set. Please try again.",
  errorDeletingRuleSetMessage: "An error occured while attempting to delete the rule set. Please try again.",
  errorGrantingUserTenantAccessMessage: "An error occured when attempting to grant access to the tenant. Please try again.",
  errorLoadingRuleSetsMessage: "There was an error loading rule sets. Please try again.",
  errorLoadingTenantSettingsMessage: "There was an error loading the settings. Please try again.",
  errorUpdatingMspTenantSettingsMessage: "An error occured when attempting to update the settings. Please try again.",
  errorUpdatingRuleSetsMessage: "An error occured while attempting to update the rule set. Please try again.",
  filterTenantsAnomaliesText: "anomalies",
  filterTenantsDropdownPlaceholder:"Filter Tenants",
  filterTenantsNoDataMessage: "The filtered tenants do not have any %RESOURCE% in the top 1000 among all tenants.",
  filterTenantsRateOptimizationText: "rate optimizations",
  filterTenantsUsageOptimizationText: "usage optimizations",
  formLabelAWS: "AWS",
  formLabelAzure: "Azure",
  formLabelGCP: "GCP",
  headerLabelClouds: "Clouds",
  headerLabelOptimizations: "Optimizations",
  hideEmailsButtonLabel: "Hide Emails",
  inputPlaceholderBillingAccountIDs: "Billing Account IDs",
  inputPlaceholderFilterStatus: "Filter by Status",
  inputPlaceholderFilterProvider: "Filter by Provider",
  inputPlaceholderProviders: "Providers",
  inputPlaceholderRegions: "Regions",
  inputPlaceholderServiceNames: "Service Names",
  inputPlaceholderSelectGroupings: "Select Groupings",
  inputPlaceholderTenants: "Tenants",
  investigateButtonLabel: "Investigate",  
  nowDateLabel: "Now",
  optimizationsTooltipContent: "Disabling these options will cause optimizations tabs to be disabled for your non MSP users in all child tenants. Rate optimization tabs will be disabled in the corresponding committed use pages. Usage optimization tabs will be disabled the corresponding insights pages.",
  providerTypeLabel_AWS: "AWS",
  providerTypeLabel_AZURE: "Azure",
  providerTypeLabel_GCP: "GCP",
  rateRecTermLabel_ONE_YEAR: "1 Year",
  rateRecTermLabel_THREE_YEARS: "3 Years",
  rateRecTermLabel_THIRTY_SIX_MONTH: "3 Years",
  rateRecTermLabel_TWELVE_MONTH: "1 Year",
  rateRecTypeLabel_COMMITTED_USE_DISCOUNT: "Committed Use Discount (GCP)",
  rateRecTypeLabel_RESERVED_INSTANCE_AWS: "Reserved Instance (AWS)",
  rateRecTypeLabel_RESERVED_INSTANCE_AZURE: "Reserved Instance (Azure)",
  rateRecTypeLabel_SAVINGS_PLAN_AWS: "Savings Plan (AWS)",
  rateRecTypeLabel_SAVINGS_PLAN_AZURE: "Savings Plan (Azure)",
  ruleSetFormAddDimension: "Add Dimension",
  ruleSetFormAddFilterButtonLabel: "Add Filter",
  ruleSetFormAddRuleLabel: "Add Rule",
  ruleSetFormAddTenant: "Add Tenant",
  ruleSetFormAdjustToListLabel: "Adjust to List",
  ruleSetFormAdjustToListTooltip: "Line items will be ajusted to list pricing before rerating is applied",
  ruleSetFormApplyToAllTenants: "Apply to all tenants?",
  ruleSetFormBackoutLineItems: "Backout Line Items?",
  ruleSetFormCostLabel: "Cost",
  ruleSetFormCreditsLabel: "Credits",
  ruleSetFormCurrencyLabel: "Currency", 
  ruleSetFormDestinationLabel: "Destination",
  ruleSetFormDimensionsLabel: "Dimensions",
  ruleSetFormDimensionsTooltipLabel: "Assign label key-value pairs to be applied to the recurring charge.",
  ruleSetFormEndDateLabel: "End Date",
  ruleSetFormMarkUpByLabel: "Mark Up By",
  ruleSetFormNetCostLabel: "Net Cost",
  ruleSetFormFiltersLabel: "Filters",
  ruleSetFormMetricLabel: "Metric",
  ruleSetFormPercentageLabel: "Percentage",
  ruleSetFormRecurringChargeTooltip: "Recurring charges are stated in a fixed amount. An amortized portion of this charge will be applied on the first day of each invoice month between the start and end date of the rule set.",
  ruleSetFormRerateLabel: "Rerate",
  ruleSetFormRerateTooltip: "Mark up by or discount by a given perentage (+/-)",
  ruleSetFormRequiredLabel: "(required)",
  ruleSetFormRulesLabel: "Rules",
  ruleSetFormSelectBillingAccountPlaceholder: "Select a billing account...",
  ruleSetFormSelectTenantPlaceholder: "Select a tenant...",
  ruleSetFormSelectTypePlaceholder: "Select Type",
  ruleSetFormStartDateLabel: "Start Date",
  ruleSetFormTenantsLabel: "Tenants",
  ruleSetFormUnitLabel: "Unit",
  ruleSetFormValueLabel: "Value",
  ruleSetPageTitle: "Billing Rules Engine",
  ruleSetFilterAppliesToAllLabel: "Applies to all",
  ruleSetTableSelectTenantsPlacholder: "Select Tenants...",
  ruleSetTypeLabel_ADJUST_TO_LIST: "Adjust To List",
  ruleSetTypeLabel_BACKOUT_LINE_ITEM: "Back Out Line Item",
  ruleSetTypeLabel_MOVE_BILLING_ACCOUNT: "Move Billing Line Items",
  ruleSetTypeLabel_RECURRING_CHARGE: "Recurring Charge",
  ruleSetTypeLabel_RERATE: "Rerate",
  sectionHeaderAnomalies: "Anomalies",
  sectionHeaderAnomalousSpend: "Anomalous Spend",
  sectionHeaderCloudDataStatus: "Cloud Data Status",
  sectionHeaderMonthlyTrends: "Monthly Trends",
  sectionHeaderRateOptimizations: "Rate Optimizations",
  sectionHeaderSavingsOpportunities: "Savings Opportunities",
  sectionHeaderTenantBreakdown: "Tenant Breakdown",
  sectionHeaderUsageOptimizations: "Usage Optimizations",
  sectionHeaderUserActivity: "Activity",
  sectionHeaderUserAccess: "Access",
  serviceTypeLabel_ACS: "ACS",
  serviceTypeLabel_AKS: "AKS",
  serviceTypeLabel_AZURE_BLOB: "Azure Blob",
  serviceTypeLabel_AZURE_SQL: "Azure SQL",
  serviceTypeLabel_AZURE_VM: "Azure VM",
  serviceTypeLabel_BIGQUERY: "BigQuery",
  serviceTypeLabel_CLOUD_RUN: "Cloud Run",
  serviceTypeLabel_CLOUDSQL: "Cloud SQL",
  serviceTypeLabel_EBS: "EBS",
  serviceTypeLabel_EC2: "EC2",
  serviceTypeLabel_EKS: "EKS",
  serviceTypeLabel_ELASTICACHE: "Elasticache",
  serviceTypeLabel_GCE: "GCE",
  serviceTypeLabel_GCS: "GCS",
  serviceTypeLabel_GKE: "GKE",
  serviceTypeLabel_MEMORY_DB: "Memory DB",
  serviceTypeLabel_OPEN_SEARCH: "Open Search",
  serviceTypeLabel_RDS: "RDS",
  serviceTypeLabel_REDSHIFT: "Redshift",
  serviceTypeLabel_S3: "S3",
  serviceTypeLabel_SAGEMAKER: "Sagemaker",
  showEmailsButtonLabel: "Show Emails",
  sideDrawerTitleCreate: "Create Rule Set",
  sideDrawerTitleEdit: "Edit Rule Set",
  statusVariantLabel_1: "Danger",
  statusVariantLabel_2: "Warning",
  statusVariantLabel_3: "Success",
  successUpdatingMspTenantSettingsMessage: "Settings have been successfully updated.",
  SUCCESS_RULE_SET_CREATED_message: "The rule set has been successfully created.",
  SUCCESS_RULE_SET_DELETED_message: "The rule set has been successfully deleted.",
  SUCCESS_RULE_SET_UPDATED_message: "The rule set has been successfully updated.",
  switchLabelAllowConfiguration: "Allow Configuration",
  switchLabelRate: "Rate",
  switchLabelUsage: "Usage",
  tabLabelAnomalies: "Anomalies",
  tabLabelClouds: "Clouds",
  tabLabelReporting: "Reporting",
  tabLabelSavings: "Savings",
  tabLabelUsers: "Users",
  tableHeaderCreatedBy: "Created By",
  tableHeaderAlertedAt: "Alerted At",
  tableHeaderBilledCost: "Billed Cost",
  tableHeaderCreatedAt: "Created At",
  tableHeaderDelta: "Delta",
  tableHeaderEmail: "Email",
  tableHeaderLastLogin: "Last Login",
  tableHeaderLastModified: "Last Modified",
  tableHeaderLastRefresh: "Last Refresh",
  tableHeaderListCost: "List Cost",
  tableHeaderLogins: "Total Logins",
  tableHeaderMomAbsolute: "MoM Delta $",
  tableHeaderMomPercent: "MoM Delta %",
  tableHeaderName: "Name",
  tableHeaderPotentialSavings: "Potential Savings",
  tableHeaderPotentialSavingsMonthly: "Potential Savings (Mth)",
  tableHeaderProviderType: "Provider",
  tableHeaderServiceType: "Service Type",
  tableHeaderStatus: "Status",  
  tableHeaderDateRange: "Date Range",
  tableHeaderRules: "Rules",
  tableHeaderRuleSetName: "Rule Set Name",
  tableHeaderTenantID: "Tenant ID",
  tableHeaderTenantName: "Tenant Name",
  tableHeaderTenants: "Tenants",
  tableHeaderTerm: "Term",
  tableHeaderType: "Type",
  tableHeaderUserCost: "User Cost",
  tenantAccessModalMessage: "You do not currently have access to this Tenant. If you have permission from your administrator to access this tenant, clicking confirm will take you there.",
  tenantAccessModalTitle: "Access Tenant",
};
