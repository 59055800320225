import { useTheme } from "@emotion/react";
import { faChevronDown, faFilter } from "@fortawesome/free-solid-svg-icons";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import {
  DateRange,
  getDateRangeFromDurationType,
  getInvoiceMonthFilters,
} from "@ternary/api-lib/analytics/utils";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import {
  DurationType,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import StackedBarChart from "@ternary/api-lib/ui-lib/charts/StackedBarChart";
import { formatTimestamp } from "@ternary/api-lib/ui-lib/charts/utils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { format, startOfMonth, sub } from "date-fns";
import { keyBy, uniqBy } from "lodash";
import React, { useMemo } from "react";
import { CSVLink } from "react-csv";
import {
  ArrayParam,
  BooleanParam,
  DateParam,
  DecodedValueMap,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { DateHelper } from "../../../lib/dates";
import DateRangeControls from "../../../ui-lib/components/DateRangeControls";
import Dropdown from "../../../ui-lib/components/Dropdown";
import SelectCheckbox from "../../../ui-lib/components/SelectCheckbox";
import IconExport from "../../../ui-lib/icons/IconExport";
import { isTimeGranularity } from "../../../utils/typeGuards";
import useGetTenantsByParentTenantID from "../../global-admin/hooks/useGetTenantsByParentTenantID";
import copyText from "../copyText";
import useGetMspDimensionValues from "../hooks/useGetMspDimensionValues";
import useGetMspSpendData from "../hooks/useGetMspSpendData";
import { mspRollupDimensions, mspRollupMeasures } from "../mspRollupSchema";
import { parseArrayParam } from "./MspDashboardPage";
import MspMonthlyTrendsTable, {
  formatDataForDisplay,
} from "./MspMonthlyTrendsTable";
import { MspTenantTable } from "./MspTenantTable";

const breakdownOptions = [
  {
    label: copyText.dimensionLabel_billingAccountId,
    value: mspRollupDimensions.billingAccountID,
  },
  {
    label: copyText.dimensionLabel_provider,
    value: mspRollupDimensions.provider,
  },
  { label: copyText.dimensionLabel_region, value: mspRollupDimensions.region },
  {
    label: copyText.dimensionLabel_serviceName,
    value: mspRollupDimensions.serviceName,
  },
];

const _granularityOptions = [
  {
    label: copyText.granularityOptionLabelDay,
    value: TimeGranularity.DAY,
  },
  {
    label: copyText.granularityOptionLabelWeek,
    value: TimeGranularity.WEEK,
  },
  {
    label: copyText.granularityOptionLabelMonth,
    value: TimeGranularity.MONTH,
  },

  {
    label: copyText.granularityOptionLabelQuarter,
    value: TimeGranularity.QUARTER,
  },
];

const searchParamConfig = {
  ba_ids: withDefault(ArrayParam, []),
  date_end: DateParam,
  date_start: DateParam,
  dimensions: withDefault(ArrayParam, []),
  duration: withDefault(
    createEnumParam(Object.values(DurationType)),
    DurationType.LAST_NINETY_DAYS
  ),
  granularity: withDefault(
    createEnumParam(Object.values(TimeGranularity)),
    TimeGranularity.WEEK
  ),
  providers: withDefault(ArrayParam, []),
  regions: withDefault(ArrayParam, []),
  s_names: withDefault(ArrayParam, []),
  show_filters: withDefault(BooleanParam, false),
  t_ids: withDefault(ArrayParam, []),
};

type SearchParamConfig = DecodedValueMap<typeof searchParamConfig>;

interface Props {
  parentTenantID: string;
}

export default function MspReportingContainer(props: Props) {
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  //
  // Search Params
  //

  const [_searchParams, setSearchParams] = useQueryParams(searchParamConfig);

  const searchParams = formatSearchParams(_searchParams);

  //
  // Queries
  //

  const { data: _mspTenants = [], isLoading: isLoadingTenants } =
    useGetTenantsByParentTenantID(props.parentTenantID, {
      enabled: gatekeeper.canReadTenantsPartner,
    });

  const preAggFilters = getPreAggFilters(searchParams);

  const { data: _mspSpendDataTotals = [], isLoading: isLoadingMspDataTotals } =
    useGetMspSpendData({
      dateRange: searchParams.dateRange,
      dimensions: searchParams.dimensions,
      parentTenantID: props.parentTenantID,
      preAggFilters,
    });

  const {
    data: _mspSpendDataTimeSeries = [],
    isLoading: isLoadingMspDataTimeSeries,
  } = useGetMspSpendData({
    dateRange: searchParams.dateRange,
    dimensions: searchParams.dimensions,
    granularity: searchParams.granularity,
    parentTenantID: props.parentTenantID,
    preAggFilters,
  });

  const datehelper = new DateHelper();

  const endDate = startOfMonth(datehelper.date);
  const startDate = startOfMonth(sub(endDate, { months: 3 }));

  const {
    data: _mspSpendDataMonthly = [],
    isLoading: isLoadingMspDataMonthly,
  } = useGetMspSpendData({
    dateRange: [startDate, endDate],
    dimensions: [],
    granularity: TimeGranularity.MONTH,
    parentTenantID: props.parentTenantID,
    preAggFilters: [],
  });

  const { data: mspDimensionValuesMap, isLoading: isLoadingDimensionValues } =
    useGetMspDimensionValues({
      dateRange: searchParams.dateRange,
      parentTenantID: props.parentTenantID,
    });

  //
  // Interaction Handlers
  //

  function handleChangeGranularity(granularity: string): void {
    if (!isTimeGranularity(granularity)) return;

    setSearchParams({ granularity });
  }

  function handleChangeDateRange(
    durationType: DurationType,
    dateRange?: DateRange | undefined
  ) {
    const dateRangeParams =
      dateRange && dateRange[0] && dateRange[1]
        ? {
            date_start: dateRange[0],
            date_end: dateRange[1],
          }
        : {
            date_start: null,
            date_end: null,
          };

    setSearchParams({
      duration: durationType,
      ...dateRangeParams,
    });
  }

  //
  // Render
  //

  const tenantsKeyedByDocID = keyBy(_mspTenants, "fsDocID");

  const mspSpendDataTimeSeries = _mspSpendDataTimeSeries.map((datum) => ({
    ...datum,
    tenantName: tenantsKeyedByDocID[datum.tenantId]?.name ?? "null",
  }));

  const mspSpendDataTotals = _mspSpendDataTotals.map((datum) => ({
    ...datum,
    tenantName: tenantsKeyedByDocID[datum.tenantId]?.name ?? "null",
  }));

  const mspSpendDataMonthly = _mspSpendDataMonthly.map((datum) => ({
    ...datum,
    tenantName: tenantsKeyedByDocID[datum.tenantId]?.name ?? "null",
  }));

  const fitleredMspSpendTotals =
    searchParams.tenantIDs.length > 0
      ? mspSpendDataTotals.filter((data) =>
          searchParams.tenantIDs.includes(data.tenantId)
        )
      : mspSpendDataTotals;

  const filteredMspMonthly =
    searchParams.tenantIDs.length > 0
      ? mspSpendDataMonthly.filter((data) =>
          searchParams.tenantIDs.includes(data.tenantId)
        )
      : mspSpendDataMonthly;

  const billingAccountIDOptions =
    mspDimensionValuesMap?.billingAccountId
      .filter((billingAccountID) => !!billingAccountID)
      .map((billingAccountID) => ({
        label: billingAccountID,
        value: billingAccountID,
      })) ?? [];

  const providerOptions =
    mspDimensionValuesMap?.provider
      .filter((provider) => !!provider)
      .map((provider) => ({
        label: provider,
        value: provider,
      })) ?? [];

  const regionOptions =
    mspDimensionValuesMap?.region
      .filter((region) => !!region)
      .map((region) => ({
        label: region,
        value: region,
      })) ?? [];

  const serviceNameOptions =
    mspDimensionValuesMap?.serviceName
      .filter((serviceName) => !!serviceName)
      .map((serviceName) => ({
        label: serviceName,
        value: serviceName,
      })) ?? [];

  const granularityOptions = _granularityOptions.map((option) => ({
    ...option,
    onClick: handleChangeGranularity,
  }));

  const selectedGranularityOption = granularityOptions.find(
    (option) => option.value === searchParams.granularity
  );

  const dimensionHeaders = searchParams.dimensions.map((dimension) => ({
    key: dimension,
    label: copyText[`dimensionLabel_${dimension}`],
  }));

  const spendDataTotalsCsvHeaders = [
    { key: "tenantName", label: copyText.tableHeaderTenantName },
    { key: "billedCost", label: copyText.tableHeaderBilledCost },
    ...dimensionHeaders,
    { key: "listCost", label: copyText.tableHeaderListCost },
  ];

  const timestampHeaders = uniqBy(filteredMspMonthly, "timestamp").map(
    (datum) => ({
      key: datum.timestamp,
      label: formatTimestamp(datum.timestamp, "MM/dd"),
    })
  );

  const spendDataMonthlyCsvHeaders = [
    { key: "tenantName", label: copyText.tableHeaderTenantName },
    ...timestampHeaders,
    { key: "monthOverMonthAbsolute", label: copyText.tableHeaderMomAbsolute },
    { key: "monthOverMonthPercent", label: copyText.tableHeaderMomPercent },
  ];

  const csvDataMonthly = useMemo(
    () => formatDataForDisplay(filteredMspMonthly),
    [filteredMspMonthly]
  );

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          marginBottom={theme.space_md}
        >
          <Text appearance="h3">{copyText.sectionHeaderTenantBreakdown}</Text>
          <Flex alignItems="center">
            <Dropdown
              options={granularityOptions}
              placement="bottom-start"
              selectedOption={selectedGranularityOption}
            >
              <Button
                iconEnd={<Icon icon={faChevronDown} />}
                marginRight={theme.space_xs}
                secondary
                size="small"
              >
                {selectedGranularityOption?.label}
              </Button>
            </Dropdown>
            <DateRangeControls
              dateRange={searchParams.dateRange}
              durationType={searchParams.duration}
              hiddenOptions={[
                DurationType.LAST_SEVEN_DAYS,
                DurationType.YESTERDAY,
              ]}
              isGrouped
              maxDate={datehelper.date}
              onChangeDateRange={handleChangeDateRange}
            />
            <Flex marginLeft={theme.space_sm}>
              <SelectCheckbox
                compact
                options={breakdownOptions}
                placeholder={copyText.inputPlaceholderSelectGroupings}
                selectedValues={searchParams.dimensions}
                width={250}
                onChange={(value) => {
                  setSearchParams({ dimensions: value.sort() });
                }}
              />
            </Flex>
            <Flex marginLeft={theme.space_sm}>
              <Button
                iconEnd={<Icon icon={faFilter} />}
                size="small"
                onClick={() =>
                  setSearchParams((currentParams) => ({
                    ...currentParams,
                    show_filters: !currentParams.show_filters,
                  }))
                }
              />
            </Flex>
            <CSVLink
              data={fitleredMspSpendTotals}
              headers={spendDataTotalsCsvHeaders}
              filename={`msp-tenant-cost-${format(new Date(), "MM-dd-yyyy")}`}
            >
              <Button
                iconStart={<IconExport />}
                marginLeft={theme.space_sm}
                secondary
                size="small"
              >
                {copyText.exportButtonLabel}
              </Button>
            </CSVLink>
          </Flex>
        </Flex>
      </Flex>
      {searchParams.showFilters && (
        <Flex justifyContent="flex-end" marginBottom={theme.space_md}>
          <Flex marginLeft={theme.space_sm}>
            <SelectCheckbox
              compact
              isLoading={isLoadingDimensionValues}
              options={billingAccountIDOptions}
              placeholder={copyText.inputPlaceholderBillingAccountIDs}
              selectedValues={searchParams.billingAccountIDs}
              width={245}
              onChange={(value) => {
                setSearchParams({ ba_ids: value.sort() });
              }}
            />
          </Flex>
          <Flex marginLeft={theme.space_sm}>
            <SelectCheckbox
              compact
              isLoading={isLoadingDimensionValues}
              options={providerOptions}
              placeholder={copyText.inputPlaceholderProviders}
              selectedValues={searchParams.providers}
              width={245}
              onChange={(value) => {
                setSearchParams({ providers: value.sort() });
              }}
            />
          </Flex>
          <Flex marginLeft={theme.space_sm}>
            <SelectCheckbox
              compact
              isLoading={isLoadingDimensionValues}
              options={regionOptions}
              placeholder={copyText.inputPlaceholderRegions}
              selectedValues={searchParams.regions}
              width={245}
              onChange={(value) => {
                setSearchParams({ regions: value.sort() });
              }}
            />
          </Flex>
          <Flex marginLeft={theme.space_sm}>
            <SelectCheckbox
              compact
              isLoading={isLoadingDimensionValues}
              options={serviceNameOptions}
              placeholder={copyText.inputPlaceholderServiceNames}
              selectedValues={searchParams.serviceNames}
              width={245}
              onChange={(value) => {
                setSearchParams({ s_names: value.sort() });
              }}
            />
          </Flex>
        </Flex>
      )}
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        height={400}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <StackedBarChart
          data={mspSpendDataTimeSeries}
          dimensions={["tenantName", ...searchParams.dimensions].map(
            (dimension) => ({
              name: dimension,
              isDate: false,
            })
          )}
          isLoading={isLoadingMspDataTimeSeries || isLoadingTenants}
          measures={[
            {
              name: mspRollupMeasures.billedCost,
              unit: UnitType.CURRENCY,
            },
          ]}
          readableKeys={{ billedCost: copyText.tableHeaderBilledCost }}
          showExperimentalTooltip
          showLegend
          timeSeriesGranularity={searchParams.granularity}
        />
      </Box>
      <Box>
        <MspTenantTable
          data={fitleredMspSpendTotals}
          dimensions={searchParams.dimensions}
          isLoading={isLoadingMspDataTotals || isLoadingTenants}
        />
      </Box>
      <Box>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          marginBottom={theme.space_xs}
        >
          <Text appearance="h3">{copyText.sectionHeaderMonthlyTrends}</Text>
          <CSVLink
            data={csvDataMonthly}
            headers={spendDataMonthlyCsvHeaders}
            filename={`msp-tenant-monthly-trends-${format(new Date(), "MM-dd-yyyy")}`}
          >
            <Button
              iconStart={<IconExport />}
              marginLeft={theme.space_sm}
              secondary
              size="small"
            >
              {copyText.exportButtonLabel}
            </Button>
          </CSVLink>
        </Flex>
        <MspMonthlyTrendsTable
          data={filteredMspMonthly}
          isLoading={isLoadingMspDataMonthly || isLoadingTenants}
        />
      </Box>
    </>
  );
}

function formatSearchParams(_searchParams: SearchParamConfig) {
  const dateRange =
    _searchParams.date_start && _searchParams.date_end
      ? [_searchParams.date_start, _searchParams.date_end]
      : getDateRangeFromDurationType(_searchParams.duration);

  return {
    billingAccountIDs: parseArrayParam(_searchParams.ba_ids),
    dateRange,
    dimensions: parseArrayParam(_searchParams.dimensions),
    duration: _searchParams.duration,
    granularity: _searchParams.granularity,
    providers: parseArrayParam(_searchParams.providers),
    regions: parseArrayParam(_searchParams.regions),
    serviceNames: parseArrayParam(_searchParams.s_names),
    showFilters: _searchParams.show_filters,
    tenantIDs: parseArrayParam(_searchParams.t_ids),
  };
}

function getPreAggFilters(searchParams: ReturnType<typeof formatSearchParams>) {
  const preAggFilters: QueryFilter[] = [];

  if (searchParams.tenantIDs.length > 0) {
    preAggFilters.push({
      name: mspRollupDimensions.tenantID,
      operator: Operator.EQUALS,
      values: searchParams.tenantIDs,
    });
  }

  if (searchParams.billingAccountIDs.length > 0) {
    preAggFilters.push({
      name: mspRollupDimensions.billingAccountID,
      operator: Operator.EQUALS,
      values: searchParams.billingAccountIDs,
    });
  }

  if (searchParams.providers.length > 0) {
    preAggFilters.push({
      name: mspRollupDimensions.provider,
      operator: Operator.EQUALS,
      values: searchParams.providers,
    });
  }

  if (searchParams.regions.length > 0) {
    preAggFilters.push({
      name: mspRollupDimensions.region,
      operator: Operator.EQUALS,
      values: searchParams.regions,
    });
  }

  if (searchParams.serviceNames.length > 0) {
    preAggFilters.push({
      name: mspRollupDimensions.serviceName,
      operator: Operator.EQUALS,
      values: searchParams.serviceNames,
    });
  }

  if (searchParams.duration === DurationType.INVOICE) {
    const invoiceMonthFilters = getInvoiceMonthFilters(searchParams.dateRange);

    preAggFilters.push(...invoiceMonthFilters);
  }

  return preAggFilters;
}
