import Form, { FormField } from "@/ui-lib/components/Form";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Modal from "@/ui-lib/components/Modal";
import getMergeState from "@/utils/getMergeState";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { isEqual, noop } from "lodash";
import React, { FormEvent, useState } from "react";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import Select from "../../../ui-lib/components/Select";
import TextInput from "../../../ui-lib/components/TextInput";
import copyText from "../copyText";
import { DownloadOCIFormField } from "./DownloadOCIFormField";

const OCID_REGEX = /([0-9a-zA-Z-_]+[.:])([0-9a-zA-Z-_])/;
const OCIRegions = [
  "ap-sydney-1",
  "ap-melbourne-1",
  "sa-saopaulo-1",
  "sa-vinhedo-1",
  "ca-montreal-1",
  "ca-toronto-1",
  "sa-santiago-1",
  "eu-paris-1",
  "eu-marseille-1",
  "eu-frankfurt-1",
  "ap-hyderabad-1",
  "ap-mumbai-1",
  "il-jerusalem-1",
  "eu-milan-1",
  "ap-osaka-1",
  "ap-tokyo-1",
  "mx-queretaro-1",
  "eu-amsterdam-1",
  "me-jeddah-1",
  "eu-jovanovac-1",
  "ap-singapore-1",
  "af-johannesburg-1",
  "ap-seoul-1",
  "ap-chuncheon-1",
  "eu-madrid-1",
  "eu-stockholm-1",
  "eu-zurich-1",
  "me-abudhabi-1",
  "me-dubai-1",
  "uk-london-1",
  "uk-cardiff-1",
  "us-ashburn-1",
  "us-chicago-1",
  "us-phoenix-1",
  "us-sanjose-1",
];

export type OracleCloud = {
  config: {
    region: string | null;
    tenancyOCID: string | null;
    userOCID: string | null;
  };
  name: string;
  validations: { name: string; success: boolean; error: string }[];
};

interface Props {
  isLoading: boolean;
  integration?: OracleCloud;
  onInteraction: (interaction: OracleIntegrationForm.Interaction) => void;
}

interface State {
  isUpdate: boolean;
  nameInput: string;
  regionInput: string;
  tenancyOCIDInput: string;
  userOCIDInput: string;
}

const initialState: State = {
  isUpdate: false,
  nameInput: "",
  regionInput: "us-ashburn-1",
  tenancyOCIDInput: "",
  userOCIDInput: "",
};

export function OracleIntegrationForm(props: Props): JSX.Element {
  const user = useAuthenticatedUser();

  const selectedCloud = props.integration;
  const [state, setState] = useState<State>(
    selectedCloud
      ? {
          ...initialState,
          isUpdate: true,
          nameInput: selectedCloud.name,
          regionInput: selectedCloud.config.region ?? "",
          tenancyOCIDInput: selectedCloud.config.tenancyOCID ?? "",
          userOCIDInput: selectedCloud.config.userOCID ?? "",
        }
      : initialState
  );
  const mergeState = getMergeState(setState);

  function handleSubmit(event: FormEvent): void {
    event.preventDefault();

    if (props.integration) {
      const hasConfigChanges = !isEqual(props.integration.config, {
        region: state.regionInput,
        tenancyOCID: state.tenancyOCIDInput,
        userOCID: state.userOCIDInput,
      });
      props.onInteraction({
        type: OracleIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE,
        ...(hasConfigChanges
          ? {
              config: {
                region: state.regionInput,
                tenancyOCID: state.tenancyOCIDInput,
                userOCID: state.userOCIDInput,
              },
            }
          : {}),
        ...(state.nameInput !== props.integration.name
          ? { name: state.nameInput }
          : {}),
      });
    } else {
      props.onInteraction({
        type: OracleIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE,
        name: state.nameInput,
        region: state.regionInput,
        userOCID: state.userOCIDInput,
        tenancyOCID: state.tenancyOCIDInput,
      });
    }
  }

  function canUpdate(): boolean {
    const completed =
      state.nameInput.trim().length > 0 &&
      state.regionInput.trim().length > 0 &&
      state.tenancyOCIDInput.trim().length > 0 &&
      state.userOCIDInput.trim().length > 0;

    if (!props.integration) return completed;

    const hasChangedText =
      state.nameInput.trim() !== props.integration.name ||
      state.regionInput.trim() !== props.integration.config.region ||
      state.tenancyOCIDInput.trim() !== props.integration.config.tenancyOCID ||
      state.userOCIDInput.trim() !== props.integration.config.userOCID;

    return completed && hasChangedText;
  }

  const selectedRegion = OCIRegions.find(
    (option) => option === state.regionInput
  );

  return (
    <Modal
      closeOnClickOutside={false}
      isOpen
      showCloseButton
      onClose={() =>
        props.onInteraction({
          type: OracleIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED,
        })
      }
    >
      <Modal.Header>
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Text appearance="h4">
            {props.integration
              ? copyText.cloudOCIFormTitleUpdate
              : copyText.cloudOCIFormTitleCreate}
          </Text>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Flex height={450} minWidth={400} scrollable>
          <Form>
            <FormField
              name="name"
              input={TextInput}
              label={copyText.cloudAttributeName}
              required
              type="text"
              value={state.nameInput}
              variant={state.nameInput.trim().length > 0 ? "success" : "danger"}
              onChange={(event) =>
                mergeState({
                  [`${event.target.name}Input`]: event.target.value,
                })
              }
            />
            <FormField
              name="tenancyOCID"
              input={TextInput}
              label={copyText.cloudOCIAttributeTenancy}
              required
              type="text"
              value={state.tenancyOCIDInput}
              variant={
                OCID_REGEX.test(state.tenancyOCIDInput) &&
                state.tenancyOCIDInput.length > 0
                  ? "success"
                  : "danger"
              }
              onChange={(event) =>
                mergeState({
                  [`${event.target.name}Input`]: event.target.value,
                })
              }
            />
            <FormField
              name="userOCID"
              input={TextInput}
              label={copyText.cloudOCIAttributeUser}
              required
              type="text"
              value={state.userOCIDInput}
              variant={
                OCID_REGEX.test(state.userOCIDInput) &&
                state.userOCIDInput.trim().length
                  ? "success"
                  : "danger"
              }
              onChange={(event) =>
                mergeState({
                  [`${event.target.name}Input`]: event.target.value,
                })
              }
            />

            <FormField label={copyText.cloudOCIAttributeRegion} required>
              <Box>
                <Select
                  placeholder={copyText.cloudOCIAttributeRegionPlaceholder}
                  options={OCIRegions.map((region) => ({
                    label: region,
                    value: region,
                  }))}
                  value={{
                    value: selectedRegion,
                    label: selectedRegion,
                  }}
                  onChange={(option) => {
                    if (option === null) {
                      return noop;
                    }
                    mergeState({ regionInput: option.value });
                  }}
                />
              </Box>
            </FormField>
            {!state.isUpdate ? (
              <Box>
                <Text>{copyText.cloudOCICertificate}</Text>
                <DownloadOCIFormField
                  text={copyText.actionMenuItemOracleCertLabel}
                  user={user}
                />
              </Box>
            ) : null}
          </Form>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={!canUpdate() || props.isLoading}
          fullWidth
          primary
          onClick={handleSubmit}
        >
          {props.isLoading ? <LoadingSpinner /> : copyText.submitButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

OracleIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE =
  `OCICloudForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE` as const;

OracleIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE =
  `OCICloudForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE` as const;

OracleIntegrationForm.INTERACTION_REFRESH_BUTTON_CLICKED =
  `OCICloudForm.INTERACTION_REFRESH_BUTTON_CLICKED` as const;

OracleIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED =
  `OCICloudForm.INTERACTION_CANCEL_BUTTON_CLICKED` as const;

interface InteractionRefreshButtonClicked {
  type: typeof OracleIntegrationForm.INTERACTION_REFRESH_BUTTON_CLICKED;
  cloudID: string;
}

interface InteractionSubmitButtonClickedCreate {
  type: typeof OracleIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE;
  name: string;
  region: string;
  tenancyOCID: string;
  userOCID: string;
}
interface InteractionSubmitButtonClickedUpdate {
  type: typeof OracleIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE;
  config?: {
    region: string;
    tenancyOCID: string;
    userOCID: string;
  };
  name?: string;
}

interface InteractionCancelButtonClicked {
  type: typeof OracleIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace OracleIntegrationForm {
  export type Interaction =
    | InteractionRefreshButtonClicked
    | InteractionSubmitButtonClickedCreate
    | InteractionSubmitButtonClickedUpdate
    | InteractionCancelButtonClicked;
}

export default OracleIntegrationForm;
