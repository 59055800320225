import { useTheme } from "@emotion/react";
import { useQueryClient } from "@tanstack/react-query";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import { DurationType } from "@ternary/api-lib/constants/enums";
import { Role } from "@ternary/api-lib/constants/roles";
import { AlertRuleEntity } from "@ternary/api-lib/core/types";
import { actions } from "@ternary/api-lib/telemetry";
import PieChart from "@ternary/api-lib/ui-lib/charts/PieChart";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { format } from "date-fns";
import { keyBy } from "lodash";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { ArrayParam, useQueryParams, withDefault } from "use-query-params";
import useGetLabelMapsByTenantID from "../../../api/core/useGetLabelMapsByTenantID";
import paths from "../../../constants/paths";
import { useActivityTracker } from "../../../context/ActivityTrackerProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { ReportConfig } from "../../../types";
import ConfirmationModal from "../../../ui-lib/components/ConfirmationModal";
import IconExclamationDiamond from "../../../ui-lib/icons/IconExclamationDiamond";
import IconExport from "../../../ui-lib/icons/IconExport";
import { updateAuthenticatedUserGrants } from "../../../utils/QueryClientUtils";
import { AlertType, postAlert } from "../../../utils/alerts";
import getMergeState from "../../../utils/getMergeState";
import useGrantUsersTenantAccess from "../../admin/hooks/useGrantUsersTenantAccess";
import {
  legacyBigQueryAlertRule,
  legacyBillingAlertRule,
} from "../../alert-tracking/defaultAlertRules";
import useGetAlertRuleByID from "../../alert-tracking/hooks/useGetAlertRuleByID";
import {
  CostAlertDimension,
  CostAlertLegacySourceRule,
} from "../../alert-tracking/types";
import {
  alertQueryFilter,
  getAnomalyDateRange,
} from "../../alert-tracking/utils";
import useGetTenantsByParentTenantID from "../../global-admin/hooks/useGetTenantsByParentTenantID";
import copyText from "../copyText";
import useGetMspChildResources from "../hooks/useGetMspChildResources";
import MspCostAlertTable from "./MspCostAlertTable";
import { parseArrayParam } from "./MspDashboardPage";

const csvHeaders = [
  { key: "tenantName", label: copyText.tableHeaderTenantName },
  { key: "createdAt", label: copyText.tableHeaderAlertedAt },
  { key: "delta", label: copyText.tableHeaderDelta },
];

type Interaction = MspCostAlertTable.Interaction;

type State = {
  selectedAlert: {
    id: string;
    dimensions: CostAlertDimension[];
    durationType: DurationType;
    eventTime: string;
  } | null;
  selectedTenantID: string | null;
  showModal: boolean;
};

const initialState: State = {
  selectedAlert: null,
  selectedTenantID: null,
  showModal: false,
};

interface Props {
  parentTenantID: string;
}

export default function MspCostAlertViewContainer(props: Props) {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const queryClient = useQueryClient();
  const theme = useTheme();

  //
  // State
  //

  const [searchParamState] = useQueryParams({
    t_ids: withDefault(ArrayParam, []),
  });

  const tenantDocIDs = parseArrayParam(searchParamState.t_ids);

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data: _mspTenants = [], isLoading: isLoadingMspTenants } =
    useGetTenantsByParentTenantID(props.parentTenantID, {
      enabled: gatekeeper.canReadTenantsPartner,
    });

  const tenantsKeyedByDocID = keyBy(_mspTenants, "fsDocID");

  const { data: mspResources, isLoading: isLoadingMspResources } =
    useGetMspChildResources(props.parentTenantID);

  const { data: alertRule, isLoading: isLoadingAlertRule } =
    useGetAlertRuleByID(state.selectedAlert?.id ?? "", {
      enabled:
        !!state.selectedAlert?.id &&
        state.selectedAlert.id !==
          CostAlertLegacySourceRule.LEGACY_BIGQUERY_ANOMALY_ML &&
        state.selectedAlert.id !==
          CostAlertLegacySourceRule.LEGACY_BILLING_ANOMALY_ML,
      includeEvents: false,
    });

  const { data: labelMaps } = useGetLabelMapsByTenantID(
    state.selectedTenantID ?? "",
    {
      enabled: !!state.selectedTenantID,
    }
  );

  //
  // Mutations
  //

  const {
    isPending: isGrantingUserTenantAccess,
    mutate: grantUsersTenantAccess,
  } = useGrantUsersTenantAccess({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorGrantingUserTenantAccessMessage,
      });
    },
    onSuccess: (_, params) => {
      updateAuthenticatedUserGrants(queryClient, params);

      window.open(
        `${window.location.origin}/?tenant_id=${params.tenantID}`,
        "_blank"
      );

      mergeState({ selectedTenantID: null, showModal: false });
    },
  });

  //
  // Side Effects
  //
  useEffect(() => {
    if (!state.selectedAlert?.id) return;

    const appliedAlertRule = assignAppliedAlertRule();

    if (!appliedAlertRule) return;

    const dateRange = getAnomalyDateRange(
      state.selectedAlert?.eventTime,
      appliedAlertRule.timeGranularity
    );
    const reportConfig: ReportConfig = {
      dataSource: appliedAlertRule.dataSource,
      dimensions: appliedAlertRule.dimensions,
      durationType: state.selectedAlert?.durationType,
      endDate: dateRange[1].toISOString(),
      filters: alertQueryFilter(state.selectedAlert?.dimensions, labelMaps),
      measures: [appliedAlertRule.measure],
      startDate: dateRange[0].toISOString(),
    };

    window.open(
      `${window.location.origin}${paths._reportBuilderNew}?&tenant_id=${state.selectedTenantID}&report_config=${JSON.stringify(reportConfig)}`,
      "_blank"
    );

    mergeState({ selectedAlert: null });
  }, [isLoadingAlertRule, state.selectedAlert?.id]);

  //
  // InteractionHandlers
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case MspCostAlertTable.INTERACTION_LINK_CLICKED: {
        const tenant = tenantsKeyedByDocID[interaction.tenantDocID];

        mergeState({
          selectedTenantID: tenant.id,
          showModal: true,
        });
        return;
      }
      case MspCostAlertTable.INTERACTION_INVESTIGATE_CLICKED: {
        activityTracker.captureAction(
          actions.SELECT_MSP_ALERT_TO_INVESTIGATE_IN_TRE,
          { value: interaction.alertID }
        );
        const tenant = tenantsKeyedByDocID[interaction.tenantDocID];

        return interaction.hasAccess
          ? mergeState({
              selectedAlert: {
                durationType: DurationType.CUSTOM,
                dimensions: interaction.dimensions,
                eventTime: interaction.eventTime,
                id: interaction.alertID,
              },
              selectedTenantID: tenant.id,
            })
          : mergeState({
              selectedTenantID: tenant.id,
              showModal: true,
            });
      }
    }
  }

  function handleSubmitTenantAccessModal() {
    if (!state.selectedTenantID) return;

    grantUsersTenantAccess({
      tenantID: state.selectedTenantID,
      grants: [
        {
          userID: authenticatedUser.id,
          email: authenticatedUser.email,
          roles: [Role.TENANT_ADMIN],
        },
      ],
    });
  }

  //
  // Helpers
  //

  function assignAppliedAlertRule() {
    let appliedAlertRule:
      | AlertRuleEntity
      | typeof legacyBillingAlertRule
      | typeof legacyBigQueryAlertRule;
    if (
      state.selectedAlert?.id ===
      CostAlertLegacySourceRule.LEGACY_BILLING_ANOMALY_ML
    ) {
      appliedAlertRule = legacyBillingAlertRule;
    } else if (
      state.selectedAlert?.id ===
      CostAlertLegacySourceRule.LEGACY_BIGQUERY_ANOMALY_ML
    ) {
      appliedAlertRule = legacyBigQueryAlertRule;
    } else {
      if (!alertRule) return;
      appliedAlertRule = alertRule;
    }

    return appliedAlertRule;
  }

  //
  // Render
  //

  const costAlerts = mspResources?.alerts.map((alert) => ({
    ...alert,
    tenantName: tenantsKeyedByDocID[alert.tenantDocID]?.name ?? "null",
  }));

  const filteredcostAlerts =
    tenantDocIDs.length > 0
      ? costAlerts?.filter((summary) =>
          tenantDocIDs.includes(summary.tenantDocID)
        )
      : costAlerts;

  const costAlertSummaries = mspResources?.alertSummaries.map((summary) => ({
    ...summary,
    tenantName: tenantsKeyedByDocID[summary.tenantDocID]?.name ?? "null",
  }));

  let filteredAlertSummaries =
    tenantDocIDs.length > 0
      ? costAlertSummaries?.filter((summary) =>
          tenantDocIDs.includes(summary.tenantDocID)
        )
      : costAlertSummaries;

  // Remove outlier summaries that are inconsistent with listed anomalies in table
  filteredAlertSummaries =
    filteredcostAlerts?.length === 0 ? [] : filteredAlertSummaries;

  const isLoading = isLoadingMspResources || isLoadingMspTenants;

  return (
    <Box>
      {state.showModal && (
        <ConfirmationModal
          isLoading={isGrantingUserTenantAccess}
          message={copyText.tenantAccessModalMessage}
          title={copyText.tenantAccessModalTitle}
          onCancel={() => mergeState({ showModal: false })}
          onConfirm={handleSubmitTenantAccessModal}
        />
      )}
      <Flex justifyContent="space-between">
        <Box width="52%">
          <Flex
            alignItems="center"
            justifyContent="space-between"
            marginBottom={theme.space_xs}
          >
            <Flex alignItems="center">
              <Text appearance="h3" marginRight={theme.space_sm}>
                {copyText.sectionHeaderAnomalies}
              </Text>
              {filteredcostAlerts?.length === 0 &&
                searchParamState.t_ids.length > 0 && (
                  <Tooltip
                    content={copyText.filterTenantsNoDataMessage.replace(
                      "%RESOURCE%",
                      copyText.filterTenantsAnomaliesText
                    )}
                    placement="right"
                    width={"15rem"}
                  >
                    <IconExclamationDiamond size={"20px"} />
                  </Tooltip>
                )}
            </Flex>
            <CSVLink
              data={filteredcostAlerts ?? []}
              headers={csvHeaders}
              filename={`msp-cost-alerts-${format(new Date(), "MM-dd-yyyy")}`}
            >
              <Button iconStart={<IconExport />} secondary size="small">
                {copyText.exportButtonLabel}
              </Button>
            </CSVLink>
          </Flex>
          <MspCostAlertTable
            costAlertSummaries={filteredcostAlerts ?? []}
            isLoading={isLoading}
            onInteraction={handleInteraction}
          />
        </Box>
        <Box height={400} width="45%">
          <Text appearance="h3">{copyText.sectionHeaderAnomalousSpend}</Text>
          <PieChart
            data={filteredAlertSummaries ?? []}
            dimensions={[{ name: "tenantName", isDate: false }]}
            isLoading={isLoading}
            measures={[{ name: "totalDelta", unit: UnitType.CURRENCY }]}
            readableKeys={{ tenantName: copyText.tableHeaderTenantName }}
            showFullLabel
            showTooltip
          />
        </Box>
      </Flex>
    </Box>
  );
}
