import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import TextInput from "@/ui-lib/components/TextInput";
import { AlertType, postAlert } from "@/utils/alerts";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import { TenantStatus, TenantType } from "@ternary/api-lib/constants/enums";
import { FeatureFlag } from "@ternary/api-lib/constants/feature-flags";
import { Role } from "@ternary/api-lib/constants/roles";
import { actions } from "@ternary/api-lib/telemetry";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { format } from "date-fns";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom";
import useGetUsersByTenantID from "../../../api/core/hooks/useGetUsersByTenantID";
import paths from "../../../constants/paths";
import { useActivityTracker } from "../../../context/ActivityTrackerProvider";
import useGatekeeper from "../../../hooks/useGatekeeper";
import useUpdateTenant from "../../../hooks/useUpdateTenant";
import { useNavigateWithSearchParams } from "../../../lib/react-router";
import { useMspStore } from "../../../lib/zustand";
import ConfirmationModal from "../../../ui-lib/components/ConfirmationModal";
import Dropdown from "../../../ui-lib/components/Dropdown";
import { FormField } from "../../../ui-lib/components/Form";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import MarkdownWrapper from "../../../ui-lib/components/MarkdownWrapper";
import Modal from "../../../ui-lib/components/Modal";
import SelectCheckbox from "../../../ui-lib/components/SelectCheckbox";
import IconExport from "../../../ui-lib/icons/IconExport";
import { updateAuthenticatedUserGrants } from "../../../utils/QueryClientUtils";
import useGrantUsersTenantAccess from "../../admin/hooks/useGrantUsersTenantAccess";
import useCreateBillingInfo from "../../msp-management/hooks/useCreateBillingInfo";
import useDeleteBillingInfo from "../../msp-management/hooks/useDeleteBillingInfo";
import useUpdateBillingInfo from "../../msp-management/hooks/useUpdateBillingInfo";
import copyText from "../copyText";
import useAddFeatureFlag from "../hooks/useAddFeatureFlag";
import useCreateTenant from "../hooks/useCreateTenant";
import useGetFeatureFlagsByTenantID from "../hooks/useGetFeatureFlagsByTenantID";
import useGetTenants from "../hooks/useGetTenants";
import useGetTenantsByParentTenantID from "../hooks/useGetTenantsByParentTenantID";
import useRemoveFeatureFlag from "../hooks/useRemoveFeatureFlag";
import BillingInformationModal from "./BillingInformationModal";
import FeatureFlagModal from "./FeatureFlagModal";
import TenantForm from "./TenantForm";
import TenantTable from "./TenantTable";

const MODAL_AUDIT_LOG = "AUDIT_LOG";
const MODAL_BILLING_INFORMATION = "BILLING_INFORMATION";
const MODAL_DEACTIVATE_CONFIRMATION = "DEACTIVATE_CONFIRMATION";
const MODAL_FEATURE_FLAGS = "FEATURE_FLAGS";
const MODAL_REMOVE_BILLING_INFO_CONFIRMATION =
  "MODAL_REMOVE_BILLING_INFO_CONFIRMATION";
const MODAL_REMOVE_FEATURE_FLAG_CONFIRMATION =
  "REMOVE_FEATURE_FLAG_CONFIRMATION";

const csvHeaders = [
  { label: copyText.tableHeaderID, key: "id" },
  { label: copyText.tableHeaderName, key: "name" },
  { label: copyText.tableHeaderCreatedAt, key: "createdAt" },
  { label: copyText.tableHeaderStatus, key: "status" },
  { label: copyText.tableHeaderType, key: "type" },
  {
    label: copyText.tableHeaderServiceAccountEmail,
    key: "serviceAccountEmail",
  },
];

type TenantTypeOptionValue = keyof typeof TenantType | "ALL";

type Interaction =
  | BillingInformationModal.Interaction
  | FeatureFlagModal.Interaction
  | TenantForm.Interaction
  | TenantTable.Interaction;

interface State {
  featureFlagToRemove: FeatureFlag | null;
  modalKey: string;
  searchTerm: string;
  selectedTenantID: string | null;
  selectedTenantIDs: string[];
  showForm: boolean;
  statusFilter: string[];
  typeFilter: TenantTypeOptionValue;
}

const initialState: State = {
  featureFlagToRemove: null,
  modalKey: "",
  searchTerm: "",
  selectedTenantID: null,
  selectedTenantIDs: [],
  showForm: false,
  statusFilter: [],
  typeFilter: "ALL",
};

const defaultFeatureFlags = [];
const defaultUsers = [];

export default function TenantManagementContainer(props: {
  isInternal?: boolean;
}): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const activityTracker = useActivityTracker();
  const gatekeeper = useGatekeeper();
  const location = useLocation();
  const navigate = useNavigateWithSearchParams();
  const queryClient = useQueryClient();
  const theme = useTheme();

  //
  // State
  //

  const mspStore = useMspStore();

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const isMspAdminLocation = location.pathname.startsWith(paths._mspMgmt);

  const {
    data: _tenants = [],
    isLoading: _isLoadingTenants,
    refetch: _refetchTenants,
  } = useGetTenants({
    enabled: gatekeeper.canReadTenantsSystem && !isMspAdminLocation,
  });

  const {
    data: _mspTenants = [],
    isLoading: _isLoadingMspTenants,
    refetch: _refetchMspTenants,
  } = useGetTenantsByParentTenantID(mspStore.selectedParentTenantID as string, {
    enabled:
      gatekeeper.canReadTenantsPartner &&
      !!mspStore.selectedParentTenantID &&
      isMspAdminLocation,
  });

  const {
    data: featureFlags = defaultFeatureFlags,
    isLoading: isLoadingFeatureFlags,
    refetch: refetchfeatureFlags,
  } = useGetFeatureFlagsByTenantID(state.selectedTenantID ?? "", {
    enabled: !!state.selectedTenantID,
  });

  const { data: users = defaultUsers, isLoading: isLoadingUsers } =
    useGetUsersByTenantID(authenticatedUser.tenant.fsDocID);

  const isLoading = isMspAdminLocation
    ? _isLoadingMspTenants
    : _isLoadingTenants;

  const refetchTenants = isMspAdminLocation
    ? _refetchMspTenants
    : _refetchTenants;

  //
  // Mutations
  //

  const { isPending: isCreatingTenant, mutate: createTenant } = useCreateTenant(
    {
      onError: () => {
        postAlert({
          type: AlertType.ERROR,
          message: copyText.errorCreatingTenantMessage,
        });
      },
      onSuccess: () => {
        mergeState({ showForm: false });

        refetchTenants();

        postAlert({
          type: AlertType.SUCCESS,
          message: copyText.successCreatingTenantMessage,
        });
      },
    }
  );

  const {
    isPending: isGrantingUserTenantAccess,
    mutate: grantUsersTenantAccess,
  } = useGrantUsersTenantAccess({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorGrantingUserTenantAccessMessage,
      });
    },
    onSuccess: (_, params) => {
      updateAuthenticatedUserGrants(queryClient, params);

      window.open(
        `${window.location.origin}/?tenant_id=${params.tenantID}`,
        "_blank"
      );

      mergeState({ modalKey: "", selectedTenantID: null });
    },
  });

  const { isPending: isUpdatingTenant, mutate: updateTenant } = useUpdateTenant(
    {
      onError: () => {
        postAlert({
          type: AlertType.ERROR,
          message: copyText.errorUpdatingTenantMessage,
        });
      },
      onSuccess: () => {
        mergeState({ modalKey: "", selectedTenantID: null, showForm: false });

        refetchTenants();

        postAlert({
          type: AlertType.SUCCESS,
          message: copyText.successUpdatingTenantMessage,
        });
      },
    }
  );

  const { isPending: isAddingFeatureFlags, mutate: addFeatureFlag } =
    useAddFeatureFlag({
      onError: () => {
        postAlert({
          type: AlertType.ERROR,
          message: copyText.errorAddingFeatureFlagsMessage,
        });
      },
      onSuccess: () => {
        refetchTenants();
        refetchfeatureFlags();

        postAlert({
          type: AlertType.SUCCESS,
          message: copyText.successAddingFeatureFlagsMessage,
        });
      },
    });

  const { isPending: isRemovingFeatureFlags, mutate: removeFeatureFlag } =
    useRemoveFeatureFlag({
      onError: () => {
        mergeState({
          featureFlagToRemove: null,
          modalKey: MODAL_FEATURE_FLAGS,
        });

        postAlert({
          type: AlertType.ERROR,
          message: copyText.errorRemovingFeatureFlagsMessage,
        });
      },
      onSuccess: () => {
        mergeState({
          featureFlagToRemove: null,
          modalKey: MODAL_FEATURE_FLAGS,
        });

        refetchTenants();
        refetchfeatureFlags();

        postAlert({
          type: AlertType.SUCCESS,
          message: copyText.successRemovingFeatureFlagsMessage,
        });
      },
    });

  const { isPending: isLoadingCreateBillingInfo, mutate: createBillingInfo } =
    useCreateBillingInfo({
      onError: () => {
        postAlert({
          type: AlertType.ERROR,
          message: copyText.errorCreatingBillingInformationMessage,
        });
      },
      onSuccess: () => {
        mergeState({ modalKey: "", selectedTenantID: null, showForm: false });

        refetchTenants();

        postAlert({
          type: AlertType.SUCCESS,
          message: copyText.successCreatingBillingInformationMessage,
        });
      },
    });

  const { isPending: isLoadingUpdateBillingInfo, mutate: updateBillingInfo } =
    useUpdateBillingInfo({
      onError: () => {
        postAlert({
          type: AlertType.ERROR,
          message: copyText.errorUpdatingBillingInfoMessage,
        });
      },
      onSuccess: () => {
        mergeState({ modalKey: "", selectedTenantID: null, showForm: false });

        refetchTenants();

        postAlert({
          type: AlertType.SUCCESS,
          message: copyText.successUpdatingBillingInfoMessage,
        });
      },
    });

  const { isPending: isLoadingDeleteBillingInfo, mutate: deleteBillingInfo } =
    useDeleteBillingInfo({
      onError: () => {
        postAlert({
          type: AlertType.ERROR,
          message: copyText.errorRemovingMspBillingInfoMessage,
        });
      },
      onSuccess: () => {
        mergeState({ modalKey: "", selectedTenantID: null, showForm: false });

        refetchTenants();

        postAlert({
          type: AlertType.SUCCESS,
          message: copyText.successRemovingBillingInfoMessage,
        });
      },
    });

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case BillingInformationModal.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({ selectedTenantID: null, modalKey: "" });
        return;
      }
      case BillingInformationModal.INTERACTION_REMOVE_BILLING_INFO_CLICKED: {
        activityTracker.captureAction(actions.DELETE_MSP_BILLING_INFO, {
          tenantID: selectedTenant?.id,
        });

        mergeState({ modalKey: MODAL_REMOVE_BILLING_INFO_CONFIRMATION });
        return;
      }
      case BillingInformationModal.INTERACTION_SUBMIT_BILLING_INFO_CLICKED: {
        if (!selectedTenant) return;

        activityTracker.captureAction(actions.CREATE_MSP_BILLING_INFO);

        createBillingInfo({
          tenantID: selectedTenant.id,
          ...interaction.params,
        });
        return;
      }
      case BillingInformationModal.INTERACTION_UPDATE_BILLING_INFO_CLICKED: {
        if (!selectedTenant) return;

        updateBillingInfo({
          billingInfoID: interaction.id,
          ...interaction.params,
        });
        return;
      }
      case FeatureFlagModal.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({
          featureFlagToRemove: null,
          selectedTenantID: null,
          modalKey: "",
        });
        return;
      }
      case FeatureFlagModal.INTERACTION_ADD_FEATURE_FLAG_CLICKED: {
        if (!selectedTenant) return;

        addFeatureFlag({
          tenantID: selectedTenant.id,
          flag: interaction.flag,
        });
        return;
      }
      case FeatureFlagModal.INTERACTION_REMOVE_FEATURE_FLAG_CLICKED: {
        if (!selectedTenant) return;

        mergeState({
          featureFlagToRemove: interaction.flag,
          modalKey: MODAL_REMOVE_FEATURE_FLAG_CONFIRMATION,
        });

        return;
      }
      case TenantForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({ selectedTenantID: null, showForm: false });
        return;
      }
      case TenantForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE: {
        createTenant({
          ...(mspStore.selectedParentTenantID && isMspAdminLocation
            ? { parentTenantID: mspStore.selectedParentTenantID }
            : {}),
          currencyCode: "USD",
          name: interaction.name,
          type: interaction.tenantType,
        });
        return;
      }
      case TenantForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        if (
          interaction.status &&
          interaction.status === TenantStatus.INACTIVE
        ) {
          mergeState({
            modalKey: MODAL_DEACTIVATE_CONFIRMATION,
            showForm: false,
          });
          return;
        }

        updateTenant({
          tenantID: interaction.tenantID,
          name: interaction.name,
          status: interaction.status,
        });
        return;
      }
      case TenantTable.INTERACTION_BILLING_INFORMATION_BUTTON_CLICKED: {
        activityTracker.captureAction(actions.SELECT_MSP_BILLING_INFO, {
          tenantID: interaction.tenantID,
        });

        mergeState({
          modalKey: MODAL_BILLING_INFORMATION,
          selectedTenantID: interaction.tenantID,
        });
        return;
      }
      case TenantTable.INTERACTION_GRANT_TENANT_ACCESS_BUTTON_CLICKED: {
        const existingGrant = authenticatedUser.grants.find(
          (grant) => grant.tenantDocID === interaction.tenantID
        );

        // For MSP Partners, can grant access to a tenant without
        // any extra steps.
        if (!gatekeeper.canAccessInternalAdmin) {
          if (existingGrant) {
            window.open(
              `${window.location.origin}/?tenant_id=${interaction.tenantID}`,
              "_blank"
            );
          } else {
            grantUsersTenantAccess({
              tenantID: interaction.tenantID,
              grants: [
                {
                  userID: authenticatedUser.id,
                  email: authenticatedUser.email,
                  roles: [Role.TENANT_ADMIN],
                },
              ],
            });
          }
          return;
        }

        // For Ternary employees, we need to fill out the audit log before
        // we can get tenant acess granted.
        mergeState({
          modalKey: MODAL_AUDIT_LOG,
          selectedTenantID: interaction.tenantID,
        });
        return;
      }
      case TenantTable.INTERACTION_MANAGE_FEATURE_FLAGS_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_FEATURE_FLAGS,
          selectedTenantID: interaction.tenantID,
        });
        return;
      }
      case TenantTable.INTERACTION_OPEN_EDIT_FORM_BUTTON_CLICKED: {
        mergeState({ showForm: true, selectedTenantID: interaction.tenantID });
        return;
      }
      case TenantTable.INTERACTION_VIEW_TENANTS_BUTTON_CLICKED: {
        navigate(
          paths._internalMspPartnerTenants.replace(
            ":parentTenantID",
            interaction.tenantID
          )
        );
        return;
      }
      case TenantTable.INTERACTION_VIEW_USERS_BUTTON_CLICKED: {
        const path = isMspAdminLocation
          ? paths._mspAdminTenantUsers
          : paths._internalTenantUsers;

        navigate(path.replace(":tenantID", interaction.tenantID));
        return;
      }
    }
  }

  function handleSubmitAuditLogModal(reason: string) {
    if (!state.selectedTenantID) return;

    grantUsersTenantAccess({
      tenantID: state.selectedTenantID,
      grants: [
        {
          userID: authenticatedUser.id,
          email: authenticatedUser.email,
          roles: [Role.TENANT_ADMIN],
        },
      ],
      reason,
    });
  }

  //
  // Side Effects
  //

  //
  // Render
  //

  // Filter tenants depending on which view we are in
  let tenants = isMspAdminLocation ? _mspTenants : _tenants;

  tenants = isMspAdminLocation
    ? tenants.filter(
        (_tenant) => _tenant.id !== mspStore.selectedParentTenantID
      )
    : tenants;

  if (
    state.searchTerm.length > 0 ||
    state.selectedTenantIDs.length > 0 ||
    state.statusFilter.length > 0 ||
    state.typeFilter !== "ALL"
  ) {
    tenants = tenants.filter((tenant) => {
      const searchTermMatch =
        state.searchTerm.length > 0
          ? [tenant.id, tenant.fsDocID, tenant.name, tenant.serviceAccountEmail]
              .join("")
              .toLowerCase()
              .includes(state.searchTerm.toLowerCase())
          : true;

      const idMatch =
        state.selectedTenantIDs.length > 0
          ? state.selectedTenantIDs.includes(tenant.id)
          : true;

      const statusMatch =
        state.statusFilter.length > 0
          ? state.statusFilter.includes(tenant.status)
          : true;

      const typeMatch =
        state.typeFilter !== "ALL" ? state.typeFilter === tenant.type : true;

      return searchTermMatch && idMatch && statusMatch && typeMatch;
    });
  }

  const selectedTenant = tenants.find(
    (tenant) => tenant.id === state.selectedTenantID
  );

  let options = [
    {
      label: copyText.tenantTypeOption_ALL,
      value: "ALL",
    },
    {
      label: copyText.tenantTypeOption_DIRECT_CUSTOMER,
      value: TenantType.DIRECT_CUSTOMER,
    },
    {
      label: copyText.tenantTypeOption_INTERNAL,
      value: TenantType.INTERNAL,
    },
    {
      label: copyText.tenantTypeOption_MSP_CUSTOMER,
      value: TenantType.MSP_CUSTOMER,
    },
    {
      label: copyText.tenantTypeOption_MSP_PARENT,
      value: TenantType.MSP_PARENT,
    },
  ];

  options = options.map((option) => ({
    ...option,
    onClick: (value) => mergeState({ typeFilter: value }),
  }));

  const tenantFilterOption = _mspTenants
    .map((tenant) => ({
      label: tenant.name,
      value: tenant.id,
    }))
    .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));

  const statusOptions = [
    {
      label: copyText.tenantStatusOption_ACTIVE,
      value: TenantStatus.ACTIVE,
    },
    {
      label: copyText.tenantStatusOption_INACTIVE,
      value: TenantStatus.INACTIVE,
    },
  ];

  function renderModal() {
    switch (state.modalKey) {
      case MODAL_AUDIT_LOG: {
        return (
          <AuditLogModal
            isProcessing={isGrantingUserTenantAccess}
            onClose={() => mergeState({ modalKey: "", selectedTenantID: null })}
            onSubmit={handleSubmitAuditLogModal}
          />
        );
      }
      case MODAL_BILLING_INFORMATION: {
        return (
          <BillingInformationModal
            billingInfo={selectedTenant?.mspBillingInfo}
            tenantName={selectedTenant?.name}
            isLoading={
              isLoadingCreateBillingInfo ||
              isLoadingUpdateBillingInfo ||
              isLoadingDeleteBillingInfo
            }
            onInteraction={handleInteraction}
          />
        );
      }

      case MODAL_DEACTIVATE_CONFIRMATION: {
        return (
          <ConfirmationModal
            closeOnClickOutside={false}
            isLoading={isUpdatingTenant}
            message={
              <MarkdownWrapper>
                {copyText.deactivateTenantConfirmationMessage}
              </MarkdownWrapper>
            }
            title={copyText.deactivateTenantConfirmationTitle}
            variant="danger"
            onCancel={() => mergeState({ modalKey: "", showForm: true })}
            onConfirm={() =>
              updateTenant({
                tenantID: state.selectedTenantID as string,
                status: TenantStatus.INACTIVE,
              })
            }
          />
        );
      }
      case MODAL_REMOVE_BILLING_INFO_CONFIRMATION: {
        return (
          <ConfirmationModal
            closeOnClickOutside={false}
            isLoading={isLoadingDeleteBillingInfo}
            message={copyText.deleteBillingInfoConfirmationMessage}
            title={copyText.deleteBillingInfoConfirmationTitle}
            variant="danger"
            onCancel={() =>
              mergeState({
                modalKey: MODAL_BILLING_INFORMATION,
              })
            }
            onConfirm={() => {
              if (selectedTenant?.mspBillingInfo) {
                deleteBillingInfo({
                  billingInfoID: selectedTenant.mspBillingInfo.id,
                });
              }
            }}
          />
        );
      }
      case MODAL_FEATURE_FLAGS: {
        return (
          <FeatureFlagModal
            existingFeatureFlags={featureFlags}
            isLoading={
              isLoadingFeatureFlags ||
              isAddingFeatureFlags ||
              isRemovingFeatureFlags ||
              isLoadingUsers
            }
            tenantName={selectedTenant?.name}
            users={users}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_REMOVE_FEATURE_FLAG_CONFIRMATION: {
        return (
          <ConfirmationModal
            isLoading={isRemovingFeatureFlags}
            message={copyText.removeFeatureFlagWarningText}
            title={copyText.removeFeatureFlagLabel}
            variant="danger"
            onCancel={() => {
              mergeState({
                featureFlagToRemove: null,
                modalKey: MODAL_FEATURE_FLAGS,
              });
            }}
            onConfirm={() => {
              if (!selectedTenant || !state.featureFlagToRemove) return;

              removeFeatureFlag({
                tenantID: selectedTenant.id,
                flag: state.featureFlagToRemove,
              });
            }}
          />
        );
      }
    }
  }

  return (
    <>
      {renderModal()}
      {state.showForm && (
        <TenantForm
          isInternalMode={!isMspAdminLocation}
          isOpen
          isProcessing={isCreatingTenant || isUpdatingTenant}
          selectedTenant={selectedTenant}
          onInteraction={handleInteraction}
        />
      )}
      <Flex
        alignItems="center"
        marginBottom={theme.space_md}
        justifyContent="space-between"
      >
        <Flex alignItems="center">
          <Text
            fontSize={theme.h4_fontSize}
          >{`${copyText.tenantCountLabel} (${tenants.length})`}</Text>
        </Flex>
        <Flex>
          <Box marginRight={theme.space_md} width={300}>
            <TextInput
              iconEnd={
                <Icon color={theme.text_color_secondary} icon={faSearch} />
              }
              onChange={(event) =>
                mergeState({ searchTerm: event.target.value })
              }
              placeholder={copyText.searchInputPlaceholder}
              size="medium"
              value={state.searchTerm}
            />
          </Box>
          {isMspAdminLocation && (
            <Box marginRight={theme.space_sm}>
              <SelectCheckbox
                compact
                isClearable
                isLoading={_isLoadingMspTenants}
                options={tenantFilterOption}
                placeholder={copyText.filterTenantsPlaceholderText}
                selectedValues={state.selectedTenantIDs}
                width={300}
                onChange={(values) => {
                  mergeState({ selectedTenantIDs: values });
                }}
              />
            </Box>
          )}
          {isMspAdminLocation && (
            <Box marginRight={theme.space_sm} width={160}>
              <SelectCheckbox
                compact
                isLoading={_isLoadingMspTenants}
                options={statusOptions}
                selectedValues={state.statusFilter}
                onChange={(values) => {
                  mergeState({ statusFilter: values });
                }}
              />
            </Box>
          )}
          <CSVLink
            data={tenants}
            filename={`tenants-${format(new Date(), "MM-dd-yyyy")}`}
            headers={csvHeaders}
          >
            <Button
              disabled={isLoading}
              iconStart={<IconExport />}
              marginRight={theme.space_md}
              secondary
              size="small"
            >
              {copyText.actionExport}
            </Button>
          </CSVLink>
          {isMspAdminLocation ? null : (
            <Dropdown options={options}>
              <Button
                marginRight={theme.space_md}
                secondary
                size="small"
                width={140}
              >
                {copyText[`tenantTypeOption_${state.typeFilter}`]}
              </Button>
            </Dropdown>
          )}
          <Button
            iconStart={<Icon icon={faPlus} />}
            secondary
            size="small"
            onClick={() => mergeState({ showForm: true })}
          >
            {copyText.createTenantButtonLabel}
          </Button>
        </Flex>
      </Flex>
      <TenantTable
        isInternal={!!props.isInternal}
        isLoading={isLoading}
        isProcessing={isGrantingUserTenantAccess}
        tenants={tenants}
        onInteraction={handleInteraction}
      />
    </>
  );
}

interface AuditLogModalProps {
  isProcessing?: boolean;
  onClose: () => void;
  onSubmit: (reason: string) => void;
}

function AuditLogModal(props: AuditLogModalProps) {
  const [reasonInput, setReasonInput] = useState("");

  function handleKeyDown(event: React.KeyboardEvent) {
    if (
      event.key === "Enter" &&
      reasonInput.length > 0 &&
      !props.isProcessing
    ) {
      event.preventDefault();
      props.onSubmit(reasonInput);
    }
  }

  return (
    <Modal
      closeOnClickOutside={false}
      isOpen={true}
      showCloseButton
      width={500}
      onClose={props.onClose}
    >
      <Modal.Header>
        <Text appearance="h4">{copyText.auditLogModalTitle}</Text>
      </Modal.Header>
      <Modal.Body>
        <FormField
          autoFocus
          input={TextInput}
          label={copyText.reasonInputLabel}
          value={reasonInput}
          onChange={(event) => setReasonInput(event.target.value)}
          onKeyDown={(event) => handleKeyDown(event)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={props.isProcessing}
          secondary
          width={70}
          onClick={props.onClose}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={reasonInput.length === 0 || props.isProcessing}
          primary
          width={70}
          onClick={() => props.onSubmit(reasonInput)}
        >
          {props.isProcessing ? <LoadingSpinner /> : copyText.submitButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
