import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import { FormField } from "@/ui-lib/components/Form";
import Select from "@/ui-lib/components/Select";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import { AzureIntegrationType } from "@ternary/api-lib/constants/enums";
import Box from "@ternary/web-ui-lib/components/Box";
import Text from "@ternary/web-ui-lib/components/Text";
import { noop } from "lodash";
import React, { ChangeEvent } from "react";
import { validate as isUUID } from "uuid";
import copyText from "../../copyText";
import { DownloadAzureFormField } from "../DownloadAzureFormField";

interface Props {
  appIDInput: string;
  azureTypeInput: AzureIntegrationType;
  directoryIDInput: string;
  isUpdate: boolean;
  nameInput: string;
  onUpdate: (event: ChangeEvent<HTMLInputElement>) => void;
}

type TypeOption = {
  label: string;
  value: AzureIntegrationType;
};

export default function AzureFormBasic(props: Props): JSX.Element {
  const theme = useTheme();
  const user = useAuthenticatedUser();

  const typeOptions: TypeOption[] = [
    {
      label: copyText.azureCloudFormTypeAUTOLabel,
      value: AzureIntegrationType.AUTO,
    },
    {
      label: copyText.azureCloudFormTypeEALabel,
      value: AzureIntegrationType.EA,
    },
    {
      label: copyText.azureCloudFormTypePAYGLabel,
      value: AzureIntegrationType.PAYG,
    },
    {
      label: copyText.azureCloudFormTypeMCALabel,
      value: AzureIntegrationType.MCA,
    },
    {
      label: copyText.azureCloudFormTypeAPNALabel,
      value: AzureIntegrationType.APNA,
    },
    {
      label: copyText.azureCloudFormTypeMPAv2Label,
      value: AzureIntegrationType.MPAv2,
    },
  ];

  const selectedType = typeOptions.find(
    (option) => option.value === props.azureTypeInput
  );

  return (
    <Box minWidth={400}>
      <FormField
        name="name"
        input={TextInput}
        label={copyText.cloudAttributeName}
        required
        type="text"
        value={props.nameInput}
        variant={props.nameInput.trim().length > 0 ? "success" : "danger"}
        onChange={props.onUpdate}
      />
      {props.isUpdate ? (
        <Box marginBottom={theme.space_md}>
          <Text bold>{copyText.appIDLabel}</Text>
          <Text>{props.appIDInput}</Text>
        </Box>
      ) : (
        <FormField
          name="appID"
          input={TextInput}
          label={copyText.cloudAzureAttributeAppID}
          required
          type="text"
          value={props.appIDInput}
          variant={
            props.appIDInput.trim().length > 0 && isUUID(props.appIDInput)
              ? "success"
              : "danger"
          }
          onChange={props.onUpdate}
        />
      )}
      {props.isUpdate ? (
        <Box marginBottom={theme.space_md}>
          <Text bold>{copyText.cloudAzureAttributeDirectory}</Text>
          <Text>{props.directoryIDInput}</Text>
        </Box>
      ) : (
        <FormField
          name="directoryID"
          input={TextInput}
          label={copyText.cloudAzureAttributeDirectory}
          required
          type="text"
          value={props.directoryIDInput}
          variant={
            props.directoryIDInput.trim().length > 0 &&
            isUUID(props.directoryIDInput)
              ? "success"
              : "danger"
          }
          onChange={props.onUpdate}
        />
      )}
      <FormField label={copyText.azureCloudFormTypeDefaultLabel}>
        <Box width={240}>
          <Select
            isClearable
            options={typeOptions}
            placeholder={copyText.cloudAWSReportDefaultRegionLabel}
            value={
              props.azureTypeInput && selectedType
                ? {
                    value: selectedType.value,
                    label: selectedType.label,
                  }
                : null
            }
            onChange={(option) => {
              if (option === null) {
                return noop;
              }
              props.onUpdate({
                target: {
                  name: "azureType",
                  value: option.value,
                },
              } as ChangeEvent<HTMLInputElement>);
            }}
          />
        </Box>
      </FormField>
      {!props.isUpdate && (
        <FormField label={copyText.actionMenuItemDownloadAzureCert}>
          <Box marginBottom={theme.space_lg}>
            <DownloadAzureFormField
              text={copyText.actionMenuItemAzureCertLabel}
              user={user}
            />
          </Box>
        </FormField>
      )}
    </Box>
  );
}
